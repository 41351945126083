<template>
  <div class="settings">
    <div class="title">Безопасность</div>
    <form class="flex" @submit.prevent="update">
      <div class="form-group full">
        <label>Логин</label>
        <input
          type="text"
          :disabled="user.login"
          v-model="form.login"
          class="form-control"
          placeholder="Логин"
        />
        <span v-if="errors.login" class="text-error">{{ errors.login }}</span>
      </div>

      <div class="form-group full">
        <label>Старый пароль</label>
        <input
          v-if="user.login"
          type="password"
          v-model="form.old_password"
          class="form-control"
          placeholder="Пароль"
        />
        <span v-if="errors.password" class="text-error">{{
          errors.password
        }}</span>
      </div>

      <div class="form-group">
        <label>Новый пароль</label>
        <input
          type="password"
          v-model="form.password"
          class="form-control"
          placeholder="Пароль"
        />
        <span v-if="errors.password" class="text-error">{{
          errors.password
        }}</span>
      </div>

      <div class="form-group">
        <label>Повторите пароль</label>
        <input
          type="password"
          class="form-control"
          v-model="form.password_confirmation"
          placeholder="Повторите пароль"
        />
        <span v-if="errors.password" class="text-error">{{
          errors.password
        }}</span>
      </div>

      <button type="submit" class="submit">Сохранить</button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState("user", ["user"]),
  data() {
    return {
      errors: {
        password: null,
        login: null,
      },
      form: {
        old_password: "",
        login: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  mounted() {
    this.form.login = this.user?.login || "";
  },
  methods: {
    async update() {
      this.errors = {
        password: null,
        login: null,
      };
      try {
        let form = { ...this.form };
        if (this.user?.login) delete form.login;
        if (!this.user?.login) delete form.old_password;
        const { data } = await this.$http.post("user/changePassword", form);
        if (data.error) return this.$toast.error(data.error);
        return this.$toast.success(data.response.message);
      } catch (error) {
        if (error?.status === 400) {
          for (let key in error.data.error) {
            this.errors[key] = error.data.error[key][0];
          }
        } else this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .text-error {
      display: block;
      margin-top: 5px;
      color: #f4511e;
      font-size: 15px;
      font-weight: 500;
    }
    .form-group {
      width: calc(50% - 10px);
      @media (max-width: 768px) {
        width: 100%;
      }
      margin-bottom: 15px;
      &.full {
        width: 100%;
      }
      label {
        margin-bottom: 10px;
        display: block;
        font-size: 14px;
      }
      input.form-control,
      select {
        background: #f8fafc;
        border-radius: 6px;
        width: 100%;
        font-size: 16px;
        height: 50px;
        padding: 0 15px;
        color: #63788d;
        &::placeholder {
          color: rgba(#63788d, 0.5);
        }
      }
    }
    button.submit {
      background-color: #0085ff;
      padding: 15px 20px;
      border-radius: 6px;
      color: white;
      font-size: 18px;
      width: 150px;
      @media (max-width: 768px) {
        width: 100%;
      }
      height: 50px;
      transition: 200ms ease;
      &:hover {
        cursor: pointer;
      }
      margin-bottom: 15px;
    }
  }
}
</style>
